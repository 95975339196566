import React from 'react';
import Block from 'components/Block';
import Button from 'components/Button';
import SafeHtml from 'src/lib/components/SafeHtml';
import Spacer from 'components/Spacer';
import Container from 'components/Container';
import Typography from '../Typography';

interface PropsCommon {
  title?: string,
  buttonHref?: string,
  buttonText?: string,
}

type PropsHtmlContent = PropsCommon & {
  copyHtml?: string,
  copyText?: never,
};

type PropsTextContent = PropsCommon & {
  copyHtml?: never,
  copyText?: string,
};

export type Props = PropsHtmlContent | PropsTextContent;

const CtaBlock2:React.FC<Props> = ({
  title,
  buttonHref,
  buttonText,
  copyHtml,
  copyText,
}) => {
  return (
    <Block title={title} darkBg contain>
      <Container readableWidth xPadding={0} yPadding={0}>
        {(copyHtml || copyText) && (
          <Spacer mb={2}>
            {copyHtml
              ? <SafeHtml html={copyHtml} />
              : <Typography>{copyText}</Typography>}
          </Spacer>
        )}
        {buttonHref && buttonText && (
          <Button
            href={buttonHref}
            type="secondary"
          >
            {buttonText}
          </Button>
        )}
      </Container>
    </Block>
  );
};
export default CtaBlock2;
