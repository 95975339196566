import { IGatsbyImageData } from 'gatsby-plugin-image';
import {
  Image as PageImagesImage,
} from 'src/components/PageImages';

type RequiredInput = ReadonlyArray<{
  readonly image?: {
    altText?: string,
    localFile?: {
      childImageSharp?: {
        gatsbyImageData?: IGatsbyImageData,
      }
    }
  }
} | undefined>;

export default (queryData?: RequiredInput): PageImagesImage[] | null => {
  if (!queryData || !queryData.length) return null;
  const rtn: PageImagesImage[] = queryData.reduce<PageImagesImage[]>((acc, image) => {
    const imageData: IGatsbyImageData | undefined =
      image?.image?.localFile?.childImageSharp?.gatsbyImageData;
    if (imageData) {
      acc.push({
        data: imageData,
        alt: image?.image?.altText || '',
      });
    }
    return acc;
  }, []);
  return rtn || null;
};
