import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import Container from 'src/components/Container';
import Spacer from 'components/Spacer';
import TileGrid from 'components/TileGrid';

export interface Image {
  data: IGatsbyImageData,
  alt: string,
}

export interface Props {
  images: Image[],
}

const PageImages:React.FC<Props> = ({ images }) => {
  return (
    <Spacer mt={2}>
      <Container yPadding={0}>
        <TileGrid itemsPerRow={2}>
          {images.slice(0, 2).map(({ data, alt }, i) => (
            <GatsbyImage key={i} image={data} alt={alt} />
          ))}
        </TileGrid>
      </Container>
    </Spacer>
  );
};
export default PageImages;
