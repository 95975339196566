import React from 'react';
import { CaseStudy } from 'src/models/CaseStudy';
import styled from 'styled-components';
import GenericGridBlock from 'components/GenericGridBlock';
import { resolveUrl } from 'src/helpers';
import GeneralTile from 'components/tiles/GeneralTile';

export interface Props {
  caseStudies: CaseStudy[],
  title?: string,
  smallTitle?: boolean,
}

const CaseStudiesBlock:React.FC<Props> = ({ caseStudies, title = 'Case Studies.', smallTitle }) => {
  return (
    <CaseStudiesBlockOuter>
      <GenericGridBlock title={title} smallTitle={smallTitle}>
        {caseStudies.map((caseStudy) => {
          const linkedClient = caseStudy?.linkedClients?.length && caseStudy.linkedClients[0];
          return (
            <GeneralTile
              key={caseStudy._id}
              image={caseStudy.image}
              title={linkedClient ? linkedClient.name : undefined}
              subtitle={caseStudy.title}
              href={resolveUrl.caseStudy(caseStudy.slug)}
            />
          );
        })}
      </GenericGridBlock>
    </CaseStudiesBlockOuter>
  );
};
export default CaseStudiesBlock;

const CaseStudiesBlockOuter = styled.div``;
