import { useStaticQuery, graphql } from 'gatsby';

export default (): GatsbyTypes.CompanyValuesSingleFragment['companyValues'] => {
  const result = useStaticQuery<GatsbyTypes.CompanyValuesQuery>(graphql`
    fragment CompanyValuesSingle on WpSiteSettings_Acf {
      companyValues {
        name
        description
      }
    }
    query CompanyValues {
      allWp {
        nodes {
          siteSettings {
            acf {
              ...CompanyValuesSingle
            }
          }
        }
      }
    }
  `);
  return result.allWp.nodes[0].siteSettings?.acf?.companyValues || [];
};
