import React from 'react';
import { graphql } from 'gatsby';
import Container from 'components/Container';
import Block from 'components/Block';
import PageHeader from 'components/PageHeader';
import Breadcrumbs, { Props as BreadcrumbsProps } from 'src/components/Breadcrumbs';
import Seo from 'src/lib/components/Seo';
import useCompanyValues from 'src/hooks/queries/useCompanyValues';
import Accordion from 'src/components/Accordion';
import { AccordionItem } from 'src/components/Accordion/types';
import { nanoid } from 'nanoid';
import CtaBlock2 from 'src/components/CtaBlock2';
import useSiteText from 'src/hooks/queries/useSiteText';
import { resolveUrl } from 'src/helpers';
import CaseStudiesBlock from 'src/components/CaseStudiesBlock';
import useCaseStudies from 'src/hooks/queries/useCaseStudies';
import CaseStudyQuery_CaseStudy from 'src/transformers/CaseStudyQuery_CaseStudy';
import isNotNull from 'src/types/guards/isNotNull';
import AwardsBlock from 'src/components/AwardsBlock';
import FeatureCopyBlock from 'src/components/FeatureCopyBlock';
import PageImagesRawArray_PageImagesCleanArray from 'src/transformers/PageImagesRawArray_PageImagesCleanArray';
import PageImages from 'src/components/PageImages';
import ContentParser from 'src/components/ContentParser';
import BasicQuery_SitePage from 'src/transformers/BasicQuery_SitePage';


interface Props {
  data: GatsbyTypes.AboutPageQuery,
  // pageContext: { caseStudyId: string },
}

export const query = graphql`
  query AboutPage($pageId: String) {
    wpPage(id: { eq: $pageId }) {
      ...BasicPageBasic
      acf {
        copyBlock1
        copyBlock2
        copyBlock3
        pageImages {
          image {
            ...PageImagesImage
          }
        }
      }
    }
  }
`;


const AboutPage: React.FC<Props> = ({ data }) => {
  const {
    careersCta = '',
    contactCta = '',
  } = useSiteText();
  const companyValues = useCompanyValues() || [];
  const caseStudiesRaw = useCaseStudies();
  const page = data.wpPage;
  const pageData = BasicQuery_SitePage(data.wpPage || {});
  if (!page) return null;

  const caseStudies = Array.from(caseStudiesRaw, CaseStudyQuery_CaseStudy).filter(isNotNull);
  const breadcrumbs: BreadcrumbsProps['items'] = [
    { label: pageData.navTitle },
  ];

  const accordionItems = companyValues.map<AccordionItem>((item) => ({
    id: nanoid(),
    title: item?.name || '',
    contentHTML: item?.description || '',
  }));

  const pageImages = PageImagesRawArray_PageImagesCleanArray(page.acf?.pageImages)
    ?.filter(isNotNull);

  return (
    <>
      <Seo
        title={pageData.metaTitle}
        description={pageData.metaDescription}
      />

      <PageHeader
        h1={pageData.h1}
        backgroundText={pageData.contentHtml}
      />

      <Container readableWidth>
        <Breadcrumbs items={breadcrumbs} />
        {pageData.contentHtml && (
          <Block>
            <ContentParser>{pageData.contentHtml}</ContentParser>
          </Block>
        )}
      </Container>

      <CtaBlock2
        title="Careers."
        buttonText="See all careers"
        buttonHref={resolveUrl.career('')}
        copyHtml={careersCta}
      />

      {page.acf?.copyBlock1 && (
        <Block contain readableWidth>
          <FeatureCopyBlock copyHTML={page.acf.copyBlock1} />
        </Block>
      )}

      {pageImages && pageImages?.length > 0 && (
        <PageImages images={pageImages} />
      )}

      {page.acf?.copyBlock2 && (
        <Block contain readableWidth>
          <FeatureCopyBlock copyHTML={page.acf.copyBlock2} noPullText />
        </Block>
      )}

      {companyValues?.length > 0 && (
        <Container readableWidth>
          <Accordion items={accordionItems} title="Our Values." />
        </Container>
      )}

      {/* team */}
      <Block contain highlightBg>
        <AwardsBlock />
      </Block>

      {page.acf?.copyBlock3 && (
        <Block contain readableWidth>
          <FeatureCopyBlock copyHTML={page.acf.copyBlock3} />
        </Block>
      )}

      <CtaBlock2
        title="Get in touch."
        buttonText="Contact Us"
        buttonHref={resolveUrl.contact('')}
        copyHtml={contactCta}
      />

      {caseStudies?.length > 0 && (
        <Block>
          <Container yPadding={0}>
            <CaseStudiesBlock
              caseStudies={caseStudies.slice(0, 3)}
            />
          </Container>
        </Block>
      )}
    </>
  );
};

export default AboutPage;
