import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import SafeHtml from 'src/lib/components/SafeHtml';
import Button, { Props as ButtonProps } from 'components/Button';
import Spacer from 'components/Spacer';

export interface PropsCommon {
  copyHTML: string,
  maxWidth?: React.CSSProperties['maxWidth'],
  noPullText?: boolean,
}

interface PropsWithButton {
  ctaButtonText: string,
  ctaButtonProps: ButtonProps,
}

interface PropsWithoutButton {
  ctaButtonText?: never,
  ctaButtonProps?: never,
}

export type Props = PropsCommon & (PropsWithButton | PropsWithoutButton);

const FeatureCopyBlock:React.FC<Props> = ({
  copyHTML,
  maxWidth: maxWidthProp,
  ctaButtonText,
  ctaButtonProps,
  noPullText = false,
}) => {
  const theme = React.useContext(ThemeContext);
  const maxWidth = maxWidthProp || theme.misc.readibleWidth;
  return (
    <FeatureCopyBlockOuter maxWidth={maxWidth} className={noPullText ? 'no-pull-text' : 'pull-text'}>
      <SafeHtml html={copyHTML} />
      {ctaButtonProps && (
        <Spacer my={3}>
          <Button {...ctaButtonProps}>{ctaButtonText}</Button>
        </Spacer>
      )}
    </FeatureCopyBlockOuter>
  );
};
export default FeatureCopyBlock;


// user-content
const FeatureCopyBlockOuter = styled.div<{ maxWidth: Props['maxWidth'] }>`
  max-width: ${(p) => p.maxWidth};
  &.pull-text .user-content > p:first-of-type,
  &.pull-text > p:first-of-type {
    font-size: 1.35em;
    line-height: 1.4em;
  }
`;
